<template>
  <div class="teaching">
    <!-- <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">全部</el-menu-item>
      <el-menu-item index="1">我的资源</el-menu-item>
      <el-menu-item index="3">资源库</el-menu-item>
      <el-menu-item index="2">教案库</el-menu-item>
    </el-menu> -->

    <!--我的资源-->
    <main class="main" v-if="activeIndex == 1">
      <div class="shaixuan">
        <div class="left">
          <span class="name">资源类型 ： </span>

          <el-radio-group
            v-model="teaching_type_radio"
            @input="screen_teaching">
            <el-radio
              :label="item.value"
              v-for="item in teaching_type_list"
              :key="item.value">
              {{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
        <el-button type="primary" round class="creader" @click="creader()"
          >创建资源</el-button
        >
      </div>

      <!--列表 与分页-->
      <div class="class_box">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="资源名称" width="380">
          </el-table-column>
          <!-- <el-table-column label="资源类型" width="180">
            <template v-slot:default="{ row }">
              <span>{{ interpreter("type", row.resource_type) }}</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="level.name" label="适用年级">
          </el-table-column> -->
          <!-- <el-table-column prop="school.name" label="学校"> </el-table-column> -->
          <el-table-column prop="create_time" label="上传日期" width="200">
          </el-table-column>
          <!-- <el-table-column prop="shou" label="收藏">
            <template v-slot:default="{ row }">
              <el-button
                class="shoucang"
                type="text"
                @click="collection(row)"
              ></el-button>
              <div class="img_btn" @click="collection(row)">
                <img
                  v-if="row.is_coll == 1"
                  src="../../assets/img/sc-sel.png"
                  alt=""
                />
                <img v-else src="../../assets/img/sc.png" alt="" />
              </div>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" width="220">
            <template v-slot:default="{ row }">
              <el-button
                class="addclass"
                type="text"
                @click="edit_teaching(row)"
                >编辑</el-button
              >
              <el-button
                class="remove"
                type="text"
                @click="del_teaching(row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :page-size="page_size"
          layout="total, prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"></el-pagination>
      </div>

      <!--创建教案-->
      <el-dialog :visible.sync="dialogCreader" width="840px">
        <el-form class="tan_creader">
          <h2 class="title">{{ diatitle }}</h2>
          <table class="tb">
            <tr>
              <th>资源名称：</th>
              <td>
                <el-input
                  v-model="from_data.name"
                  placeholder=""
                  class="txt"></el-input>
              </td>
              <th>资源类型：</th>
              <td>
                <el-select
                  v-model="from_data.resource_type"
                  placeholder="请选择"
                  class="txt_select">
                  <el-option
                    v-for="item in teaching_type_list"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <th>适用年级：</th>
              <td colspan="3">
                <el-select
                  v-model="from_data.level_id"
                  placeholder="请选择"
                  class="txt_select">
                  <el-option
                    v-for="item in class_list"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <th>资源文件：</th>
              <td v-show="!is_replace" colspan="3">
                <div class="up_nei">
                  <img src="../../assets/img/up.png" class="upImg" />
                  <el-upload
                    ref="upload"
                    class="bottom"
                    action=""
                    :http-request="uploadfile"
                    :show-file-list="false"
                    multiple>
                    <el-button class="btn_up" type="primary"
                      >点击上传</el-button
                    >
                  </el-upload>
                  <div class="progress_box" v-show="progress_bar">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="26"
                      :percentage="percentage"
                      text-color="#fff">
                    </el-progress>
                    <p>{{ file_message }}</p>
                  </div>
                </div>
                <div class="ts">1.文件大小不能超过20M。</div>
              </td>
              <td v-show="is_replace" colspan="3">
                <div class="img_file">
                  <img
                    v-if="currentfileType == 'file'"
                    src="../../assets/img/61.png"
                    alt="" />
                  <img
                    v-if="currentfileType == 'image'"
                    :src="from_data.path"
                    alt="" />
                  <img
                    v-if="currentfileType == 'audio'"
                    src="../../assets/img/63.png"
                    alt="" />
                  <img
                    v-if="from_data.file == 'video'"
                    src="../../assets/img/62.png"
                    alt="" />
                  <el-button type="primary" @click="replace_file"
                    >替换文件</el-button
                  >
                </div>
                <div class="ts">1.文件大小不能超过20M。</div>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="td_btn">
                <el-button
                  class="btn_quxiao"
                  type="primary"
                  @click="dialogCreader = false"
                  >取消</el-button
                >
                <el-button class="btn_tijiao" type="primary" @click="submit"
                  >确定</el-button
                >
              </td>
            </tr>
          </table>
        </el-form>
      </el-dialog>

      <!--删除弹框-->
      <el-dialog :visible.sync="del_dialog" width="600px">
        <div class="del_tan">
          <h2 class="title">温馨提示</h2>
          <div class="desc">是否删除跳绳课程一？</div>
          <div class="bottom">
            <el-button class="btn_quxiao" type="primary">取消</el-button>
            <el-button class="btn_tijiao" type="primary">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </main>

    <!--资源库-->
    <main class="main" v-if="activeIndex == 3">
      <!-- <div class="shaixuan">
        <div class="left">
          <span>资源筛选 ：</span>
          <el-radio-group v-model="coll" @input="screen_teaching">
            <el-radio :label="3">全部</el-radio>
            <el-radio :label="2">收藏</el-radio>
          </el-radio-group>
        </div>
      </div> -->
      <div class="shaixuan">
        <search @search="search" />
        <!-- <div class="left">
          <span>资源类型 ： </span>
          <el-radio-group
            v-model="teaching_type_radio"
            @input="screen_teaching"
          >
            <el-radio
              :label="item.value"
              v-for="item in teaching_type_list"
              :key="item.value"
            >
              {{ item.name }}</el-radio
            >
          </el-radio-group>
        </div> -->
      </div>

      <!--列表 与分页-->
      <div class="class_box">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="资源名称"> </el-table-column>
          <!-- <el-table-column label="资源类型" width="180">
            <template v-slot:default="{ row }">
              <span>{{ interpreter("type", row.resource_type) }}</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="level.name" label="适用年级">
          </el-table-column> -->
          <!-- <el-table-column prop="school.name" label="学校"> </el-table-column> -->
          <el-table-column prop="create_time" label="上传日期">
          </el-table-column>
          <!-- <el-table-column prop="admin.name" label="上传教师">
          </el-table-column> -->
          <!-- <el-table-column label="收藏">
            <template v-slot:default="{ row }">
              <div class="img_btn" @click="collection(row)">
                <img
                  v-if="row.is_coll == 1"
                  src="../../assets/img/sc-sel.png"
                  alt=""
                />
                <img v-else src="../../assets/img/sc.png" alt="" />
              </div>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" width="160">
            <template v-slot:default="{ row }">
              <el-button class="addclass" type="text" @click="seeClick(row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :page-size="page_size"
          layout="total, prev, pager, next"
          :total="total"
          @current-change="handleCurrentChangeZY"></el-pagination>
      </div>
    </main>
    <el-dialog title="预览" :visible.sync="dialogpreview">
      <preview
        :response_info="response_info"
        :dialogpreview="dialogpreview"></preview>

      <div v-if="response_info.file.mime == 'pdf'" class="download_btn">
        下载后可播放文件中视频（推荐使用WPS）
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import preview from "./detail";
import search from "../../components/search.vue";
export default {
  components: {
    preview,
    search,
  },
  data() {
    return {
      name: "",
      dialogpreview: false,
      response_info: {
        file: {
          mime: "",
        },
      },
      pathFile: "",
      diatitle: "创建资源",
      currentfileType: "",
      headers: {
        Authorization: "Bearer" + localStorage.getItem("token"),
      },
      progress_bar: false,
      percentage: 0,
      time_bar: null,
      activeIndex: "3",
      value: "",
      checkList: [],
      teaching_type_radio: "", //教案类型
      /*常规（ 体操：GYMNASTICS；立定跳远：LONG_JUMP；跑步：RUN_STEP；篮球：BASKETBALL；
        仰卧起坐：SIT_UP；开合跳：JUMPING；踢键子：KICKER；
        深蹲：SQUAT；高抬腿：HIGH_KNEE；原地踢臀：KICK_BUTTOCKS；其他：OTHER）
        智能（跳绳：JUMP；手环：BRACELET；蹦床：TRAMPOLINE）*/
      teaching_type_list: [
        // { value: "GYMNASTICS", name: "体操" },
        // { value: "LONG_JUMP", name: "立定跳远" },
        // { value: "RUN_STEP", name: "跑步" },
        // { value: "BASKETBALL", name: "篮球" },
        // { value: "SIT_UP", name: "仰卧起坐" },
        // { value: "JUMPING", name: "开合跳" },
        // { value: "KICKER", name: "踢键子" },
        // { value: "SQUAT", name: "深蹲" },
        // { value: "HIGH_KNEE", name: "高抬腿" },
        // { value: "KICK_BUTTOCKS", name: "原地踢臀" },
        // { value: "JUMP", name: "跳绳" },
        // { value: "BRACELET", name: "手环" },
        // { value: "TRAMPOLINE", name: "蹦床" },
        // { value: "OTHER", name: "其他" },

        { value: "DAILY_EXERCISE", name: "达标天天练" },
        { value: "PRECISE_INTERVENCE", name: "精准干预" },
        { value: "EXCELLENT_LESSON", name: "优课资源" },
        { value: "GYMNASTICS", name: "体操" },
        { value: "LONG_JUMP", name: "立定跳远" },
        { value: "RUN_STEP", name: "跑步" },
        { value: "BASKETBALL", name: "篮球" },
        { value: "SIT_UP", name: "仰卧起坐" },
        { value: "JUMPING", name: "开合跳" },
        { value: "KICKER", name: "踢键子" },
        { value: "SQUAT", name: "深蹲" },
        { value: "HIGH_KNEE", name: "高抬腿" },
        { value: "KICK_BUTTOCKS", name: "原地踢臀" },
        { value: "JUMP", name: "跳绳" },
        { value: "BRACELET", name: "手环" },
        { value: "TRAMPOLINE", name: "蹦床" },
        { value: "OTHER", name: "其他" },
      ],
      class_list: [],
      dialogCreader: false,
      tableData: [],
      coll: 3,
      page_size: 10,
      page: 1,
      total: 0,
      from_data: {
        path: "",
        resource_type: "",
        name: "",
        level_id: "",
        file: "",
      },
      is_edit: false,
      is_replace: false,
      teaching_id: "",
      fileList: [],
      del_dialog: false,
      uploading: false,
      file_message: "上传文件中",
      // 对照词典
      dictionaries: {
        train: [
          {
            AI: "智能训练模式",
            CONVENTION: "常规训练模式",
            INTEREST: "趣味训练模式",
          },
        ],
        type: [
          {
            // JUMP: "跳绳",
            // TRAMPOLINE: "蹦床",
            // GYMNASTICS: "跳操",
            // LONG_JUMP: "立定跳远",
            // RUN_STEP: "跑步",
            // BASKETBALL: "篮球",
            // SIT_UP: "仰卧起坐",
            // JUMPING: "开合跳",
            // KICKER: "踢键子",
            // SQUAT: "深蹲",
            // HIGH_KNEE: "高抬腿",
            // KICK_BUTTOCKS: "原地臀踢",

            // 改版
            DAILY_EXERCISE: "达标天天练",
            PRECISE_INTERVENCE: "精准干预",
            EXCELLENT_LESSON: "优课资源",
            GYMNASTICS: "体操",
            LONG_JUMP: "立定跳远",
            RUN_STEP: "跑步",
            BASKETBALL: "篮球",
            SIT_UP: "仰卧起坐",
            JUMPING: "开合跳",
            KICKER: "踢键子",
            SQUAT: "深蹲",
            HIGH_KNEE: "高抬腿",
            JUMP: "跳绳",
            BRACELET: "手环",
            TRAMPOLINE: "蹦床",
            OTHER: "其他",
          },
        ],
        mode: [{ COUNT: "计数", TIME: "计时", FREE: "自由" }],
      },
    };
  },
  created() {
    // this.get_class_list();
    this.get_my_teaching();
    console.log(axios.interceptors.response);
  },
  computed: {
    // 对照翻译字典
    interpreter() {
      return function (name, val) {
        for (let t in this.dictionaries) {
          if (t == name) {
            for (let i in this.dictionaries[name][0]) {
              if (i == val) {
                return this.dictionaries[name][0][i];
              }
            }
          }
        }
      };
    },
  },
  methods: {
    search(v) {
      this.name = v;
      this.get_my_teaching();
    },
    handleCurrentChange(val) {
      // this.page = val;
      // console.log(11111111111111);
      this.get_my_teaching();
    },
    handleCurrentChangeZY(val) {
      this.page = val;
      console.log(11111111111111);
      this.get_my_teaching();
    },
    // 我的资源
    async get_my_teaching() {
      console.log(this.coll, "coll");
      let my = this.activeIndex;

      if (this.coll == 2) {
        my = 2;
      }
      const res = await this.axios.get(
        `/resource?per_page=${this.page_size}&page=${this.page}&resource_type=${this.teaching_type_radio}&my=${my}&name=${this.name}`
      );

      if (res.code === 1) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      }
    },
    screen_teaching() {
      this.get_my_teaching();
    },
    // 删除
    async del_teaching(id) {
      const res = await this.axios.delete(`/resource/${id}`);

      if (res.code === 1) {
        this.$message.success("删除成功");
        this.get_my_teaching();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 编辑
    edit_teaching(row) {
      this.diatitle = "编辑资源";
      console.log(row);
      this.from_data.type = row.resource_type;
      this.from_data.resource_type = row.resource_type;
      this.from_data.level_id = row.level.id;
      this.from_data.name = row.name;
      this.from_data.path = row.file.path;
      // this.from_data.file_type = row.file_type;
      this.from_data.file = row.file.type;

      this.currentfileType = row.file.type;
      this.teaching_id = row.id;
      this.dialogCreader = true;
      this.is_edit = true;
      this.is_replace = true;
      console.log(this.from_data, "this.from_data");
    },
    getUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        return (
          c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    async uploadfile(param) {
      this.file_message = "上传文件中";
      this.uploading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let file = param.file;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(extension);
      // if (extension == "docx" || extension == "doc") {
      const res = await this.axios.post("/upload/token");
      if (res.code === 1) {
        let policyData = res.data;
        let ossUrl = policyData.host;
        let accessUrl = policyData.dir + this.getUUID() + file.name;
        let sendData = new FormData();
        sendData.append("OSSAccessKeyId", policyData.accessid);
        sendData.append("policy", policyData.policy);
        sendData.append("Signature", policyData.signature);
        sendData.append("keys", policyData.dir);
        sendData.append("key", accessUrl); //上传的文件路径
        sendData.append("success_action_status", 200); // 指定返回的状态码
        sendData.append("type", extension);
        sendData.append("file", file);
        const response = await axios.post(ossUrl, sendData);
        if (response.status === 200) {
          this.uploading.close();
          this.is_replace = true;
          // this.from_data.path = ossUrl + accessUrl;
          this.from_data.path = ossUrl + accessUrl;
          this.pathFile = accessUrl;
          // this.from_data.file_type = extension;
          this.from_data.file = extension;
          let fileType = extension;
          if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
            this.currentfileType = "image";
          } else if (
            fileType == "mp4" ||
            fileType == "m4v" ||
            fileType == "mov" ||
            fileType == "wmv" ||
            fileType == "avi"
          ) {
            this.currentfileType = "video";
          } else if (fileType == "mp3") {
            this.currentfileType = "audio";
          } else if (
            fileType == "word" ||
            fileType == "Doc" ||
            fileType == "Docx" ||
            fileType == "xls" ||
            fileType == "xlsx" ||
            fileType == "pdf"
          ) {
            this.currentfileType = "file";
          }
          this.file_message = "上传成功";
          console.log(this.from_data);
        }
      }
      // } else {
      //   this.$message.error("请上传word文件");
      //   this.uploading.close();
      // }
    },

    beforeUpload() {
      this.file_message = "上传文件中";
      this.uploading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    // 上传成功
    up_success(response, file, fileList) {
      if (response.code === 1) {
        this.is_replace = false;
        this.uploading.close();
        this.from_data.path = response.data[0].path;
        // this.from_data.file_type = response.data[0].type;
        this.from_data.file = response.data[0].type;

        //

        let filetype = response.data[0].type;

        if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
          this.currentfileType = "image";
        } else if (
          fileType == "mp4" ||
          fileType == "m4v" ||
          fileType == "mov" ||
          fileType == "wmv" ||
          fileType == "avi"
        ) {
          this.currentfileType = "video";
        } else if (fileType == "mp3") {
          this.currentfileType = "audio";
        } else if (
          fileType == "word" ||
          fileType == "Doc" ||
          fileType == "Docx" ||
          fileType == "xls" ||
          fileType == "xlsx" ||
          fileType == "pdf"
        ) {
          this.currentfileType = "file";
        }

        this.progress_bar = true;
        clearInterval(this.time_bar);
        this.time_bar = setInterval(() => {
          if (this.percentage < 100) {
            this.percentage += 2;
          } else if (this.percentage >= 100) {
            clearInterval(this.time_bar);
            this.file_message = "上传成功";
          }
        }, 100);
      } else {
        this.$message.error(response.msg);
      }
    },
    // 获取年级
    async get_class_list() {
      const res = await this.axios.get("/school/levels");

      if (res.code === 1) {
        res.data.forEach((item) => {
          this.class_list.push({ value: item.id, name: item.name });
        });
      }
    },
    //创建教案
    creader() {
      this.diatitle = "创建资源";
      this.dialogCreader = true;
      this.is_edit = false;
      this.is_replace = false;
      this.from_data = {
        path: "",
        resource_type: "",
        name: "",
        level_id: "",
        file: "",
      };
    },
    async submit() {
      if (this.is_edit) {
        const res = await this.axios.put(`/resource/${this.teaching_id}`, {
          row: this.from_data,
        });

        if (res.code === 1) {
          this.dialogCreader = false;
          this.get_my_teaching();
          Object.keys(this.from_data).forEach((key) => {
            this.from_data[key] = "";
          });
          this.progress_bar = false;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      } else {
        let row = {
          // file: this.from_data.path,
          file: this.from_data.path,
          resource_type: this.from_data.resource_type,
          name: this.from_data.name,
          level_id: this.from_data.level_id,
        };
        const res = await this.axios.post("/resource", {
          row: row,
        });

        if (res.code === 1) {
          this.dialogCreader = false;
          this.get_my_teaching();
          this.$message.success("添加成功");
          Object.keys(this.from_data).forEach((key) => {
            this.from_data[key] = "";
          });
          this.progress_bar = false;
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    //删除
    delClick() {
      this.del_dialog = true;
    },
    //查看跳转页面
    seeClick(item) {
      if (item.file_url != null) {
        window.open(item.file_url);
      } else {
        this.$message("该文件无法查看");
      }

      // this.dialogpreview = true;
      // this.response_info = item;
      // console.log(item, "===");
      // this.$router.push(`/resourcesDetails?path=${path}`);
    },

    handleSelect(key, keyPath) {
      this.activeIndex = key;
      this.coll = 3;
      this.get_my_teaching();
    },
    async collection(row) {
      const res = await this.axios.get(`/resource/${row.id}/collect`);

      if (res.code === 1) {
        this.$message.success(res.msg);
        this.get_my_teaching();
      } else {
        this.$message.error(res.msg);
      }
    },
    replace_file() {
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    handlePreview() {},
    handleRemove() {},
    beforeRemove() {},
    handleExceed() {},
  },
};
</script>

<style lang="less" scoped>
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.class_box {
  padding-right: 35px;
}
.download_btn {
  color: #ff8ba0;
  margin-top: 15px;
}
/deep/.el-menu {
  background: #f2f7ff;
}
/deep/.el-menu.el-menu--horizontal {
  display: flex;
  border-bottom: solid 0px #fff;
}
.teaching {
  width: 1748px;
  height: 100%;
  background: #f2f7ff;
  position: relative;
  padding: 20px 40px 20px 20px;
  overflow: scroll;
  .el-select {
    position: absolute;
    right: 20px;
    top: 20px;
    /deep/.el-input__inner {
      border-radius: 10px;
    }
  }
  .search_user {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    .search_left {
      position: relative;
      width: 272px;
      img {
        width: 40px;
        position: absolute;
        right: 0;
        top: 0;
      }
      .el-input {
        /deep/.el-input__inner {
          border-radius: 10px;
          background-color: #f2f3f5;
        }
      }
    }
    .el-button {
      background-color: #537af2;
    }
  }
  .shaixuan {
    margin-bottom: 15px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 50px;
    span {
      font-size: 16px;
      line-height: 20px;
    }
    .left {
      display: flex;
      align-items: baseline;
      .name {
        width: 100px;
      }
      .el-radio-group {
        width: 700px;
      }
      .el-radio {
        margin-bottom: 20px;
        // /deep/.el-radio__inner {
        //   border-radius: 0px;
        // }
      }
    }
  }
  main {
    padding-top: 20px;
    /deep/.see {
      color: #537af2;
    }
    /deep/.el-dialog {
      border-radius: 20px;
    }
    .el-pagination {
      // position: absolute;
      // bottom: 2%;
      // right: 53px;
      float: right !important;
      margin-top: 40px !important;
      padding-right: 50px !important;
    }
    .addclassbtn {
      // width: 178px;
      margin: auto;
      margin-top: 40px;
      cursor: pointer;
    }
    .upload_box {
      background: #f9fafe;
      padding: 20px;
      .upload_img {
        width: 90%;
        margin: auto;
      }
      .upload_btn {
        width: 178px;
        margin: auto;
        margin-top: 22px;
        cursor: pointer;
      }
    }
  }
  .progress_box {
    width: 100%;
    height: 197px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding-top: 40px;
    .el-progress {
      width: 70%;
      margin: auto;
    }
    p {
      font-size: 18px;
      color: #999999;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .img_file {
    padding-bottom: 10px;
    display: block;
    width: 100%;
    min-height: 199px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px dashed #999999;
    text-align: center;
    padding-top: 20px;
    position: relative;
    img {
      margin: auto;
      width: 79px;
      margin-bottom: 10px;
    }
  }
  .el-table {
    height: 65%;
    overflow-y: auto;
    .img_btn {
      width: 20px;
    }
  }
}
</style>
